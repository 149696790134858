<template>
  <div class="homepage-style">
    <!-- <div class="clientsystem">
			<div class="clientsystem-title">功能模块</div>
			<div class="clientsystem-box">
				<div class="clientsystem-item" v-for="(item,index) in modelList" @click="toModel(item)">
					<van-icon  class="clientsystem-img" name="apps-o" />
					<div class="clientsystem-text">{{item.name}}</div>
				</div>
				<div class="clientsystem-item"></div>
				<div class="clientsystem-item"></div>
			</div>
		</div> -->
    <!-- <textModel v-model="show"></textModel> -->
    <!-- <van-button type="primary" @click="textChange">主要按钮</van-button> -->
    <!-- date单个时间选择 date-quantum时间段选择 -->
    <!-- <selectTime v-model="show" :type="'date-quantum'" @confirm="confirm" ></selectTime> -->
    <van-loading v-if="!isErro" size="24px" vertical>加载中，请稍后...</van-loading>
    <van-empty v-else image="error" description="加载失败，请联系管理员！" />
  </div>
</template>
<script>
//自定义组件绑定v-model
import textModel from './text.vue'
import selectTime from '@/components/selectTime.vue'
import api from '@/api/api.js'
import { Toast } from 'vant'

export default {
  data () {
    return {
      show: false,
      modelList: [
        {
          name: '签单模块',
          routerName: 'clientName',
        },
        {
          name: '深化模块',
          routerName: 'deepenName',
        },
        {
          name: '工程模块',
          routerName: 'engineeringName',
        },
        {
          name: '话务模块',
          routerName: 'telephoneName',
        }
      ],
      code: '',
      type: 1001,
      isErro: false
    }
  },
  components: {
    textModel,
    selectTime
  },
  methods: {
    toModel (item) {
      this.$router.push({ name: item.routerName })
    },
    textChange () {
      this.show = true
    },
    //选择时间确认
    confirm (val) {
      console.log(val, '返回')
    },
    //获取用户userId
    async getUserId () {
      let res = await api.getUserId({ code: this.code, agentid: this.type })
      if (res.code == 200) {
        if (!res.data.userId) {
          Toast('无权限，请联系管理员!');
          this.isErro = true
          return
        }
        sessionStorage.setItem('TOKEN', res.data.userId)
        res.data.btn = res.data.btn ? JSON.parse(res.data.btn) : []
        sessionStorage.setItem('USERINFO', JSON.stringify(res.data))
        //任务看板 清除缓存 
        localStorage.removeItem("USER_ROLE");
        localStorage.removeItem("INDEX_TAB_ACTIVE");
        
        // 1000023 准生产
        if (this.type == 1000006 || this.type == 1000023) this.$router.replace({ name: "clientSignBillListName" })//跳转客户管理-签单
        //1000025生产 1000022准生产
        if (this.type == 1000022 || this.type == 1000025) this.$router.replace({ name: "clientTelephoneOperatorName" })//跳转客户管理-客服
        if (this.type == 1000009 || this.type == 1000028) this.$router.replace({ name: "engineeringName" })//跳转工程
        if (this.type == 1000008 || this.type == 1000029) this.$router.replace({ name: "deepenName" })//跳转深化
        if (this.type == 1000011 || this.type == 1000040) this.$router.replace({ name: "storageListName" })//跳转仓储
        if (this.type == 1000012) this.$router.replace({ name: "statistics" })//跳转统计
        if (this.type == 1000036 || this.type == 1000035) {
          this.$router.replace({ name: "dataBoard" })//跳转数据看板
        }
        if (this.type == 1000045 || this.type == 1000046) {
          this.$router.replace({ name: "TaskManagement" })//任务管理中心
        }
        
        if (this.type == 1000037 ||this.type == 1000027 ){
            let list = await api.getBoardAuthority(res.data.userId)
            // this.$router.replace({ name: "dataBoard" })//跳转数据看板
            // this.$router.replace({ name: "president" })//跳转科技看板
            if(list.code == 200){
                this.$router.replace({ name: list.data })
            }
        } 
        //1000017 准生产
        if (this.type == 1000014 || this.type == 1000017) {
            if (this.$route.query.messageType == '3' || (this.$route.query.messageType == '33' && ['41', '48', '49', '54'].indexOf(this.$route.query.businessType) > -1)) {
                //审批消息
                this.$router.replace({
                    name: "messageName",
                    query: {
                        bussinessId: this.$route.query.bussinessId,
                        procInstanceId: this.$route.query.procInstanceId,
                        approvalNoticeType: this.$route.query.approvalNoticeType,
                        businessType: this.$route.query.businessType,
                        orderId: this.$route.query.orderId,
                        messageId: this.$route.query.messageId,
                        messageType: this.$route.query.messageType
                    }
                })
            } else if (this.$route.query.messageType == '4') {
                let r = await api.getDepart(res.data.userId)
                if (r.data.deptName?.indexOf('客服') > -1) {
                    // 到店通知跳转客服客户详情
                    this.$router.replace({
                        name: 'clientTDetailsName',
                        params: {
                            id: this.$route.query.consumerId
                        },
                        query: {
                            isDirectRoute: true//true直接跳转false间接跳转（从客户列表跳转）
                        }
                    })
                } else if (r.data.deptName?.indexOf('话务') > -1) {
                    // 到店通知跳转话务客户详情
                    this.$router.replace({
                        name: 'clientTelephoneDetailsName',
                        params: {
                            id: this.$route.query.consumerId
                        }
                    })
                }
            } else if (this.$route.query.messageType == '5') {
                // 分配通知跳转签单客户详情
                this.$router.replace({
                    name: 'clientDetailsName',
                    params: {
                        id: this.$route.query.consumerId
                    },
                    query: {
                        isDirectRoute: true//true直接跳转false间接跳转（从客户列表跳转）
                    }
                })

            } else if (this.$route.query.messageType == '6') {
                // 财务放款
                if(this.$route.query.documentType == '1'){
                    this.$router.replace({
                        name: 'ApplyFinancial',
                        params: {
                            id: this.$route.query.allocateId,
                            logId: this.$route.query.logId
                        }
                    })
                }else{
                    this.$router.replace({
                        name: 'ApplyFinancialNew',
                        params: {
                            id: this.$route.query.allocateId,
                            logId: this.$route.query.logId,
                            projectId: this.$route.query.projectId,
                            nodeCode: this.$route.query.nodeCode,
                        }
                    })
                }
            } else if (this.$route.query.messageType == '7') {
                // 缴费审核
                this.$router.replace({
                    name: 'AdvancePaymentCheck',
                    params: {
                        id: this.$route.query.refId,
                        logId: this.$route.query.logId
                    }
                })
            } else if (this.$route.query.messageType == '8' || this.$route.query.messageType == '9') {
                // 自理项目确认单通知退货单通知
                this.$router.replace({
                    name: 'ConfirmationSlip',
                    params: {
                        id: this.$route.query.refId,
                        logId: this.$route.query.logId
                    }
                })

            } else if (this.$route.query.messageType == '10') {
                // 自理项目验收分包单通知---上传
                this.$router.replace({
                    name: 'projectSubcontract',
                    params: {
                        id: this.$route.query.refId,
                        logId: this.$route.query.logId
                    }
                })
            } else if (this.$route.query.messageType == '11') {
                // 自理项目分包单通知
                this.$router.replace({
                    name: 'ResultSubcontract',
                    params: {
                        id: this.$route.query.refId,
                        logId: this.$route.query.logId
                    }
                })
            } else if (this.$route.query.messageType == '12') {
                // 上传施工图片
                this.$router.replace({
                    name: 'NewSubcontract',
                    params: {
                        id: this.$route.query.refId,
                        logId: this.$route.query.logId
                    }
                })

            } else if(this.$route.query.messageType == '13'){
                this.$router.replace({
                    name: 'dataBoardMessage',
                    query: {
                        logId: this.$route.query.logId
                    }
                })
            } else if (this.$route.query.messageType == '14') {
                // 下单通知
                this.$router.replace({
                    name: 'PlaceOrder',
                    params: {
                        id: this.$route.query.refId,
                        logId: this.$route.query.logId
                    }
                })
            } else if (this.$route.query.messageType == '15') {
                // 安装完毕通知
                this.$router.replace({
                    name: 'Installation',
                    params: {
                        id: this.$route.query.refId,
                        logId: this.$route.query.logId
                    }
                })
            } else if (this.$route.query.messageType == '16') {
                let routerName = this.$route.query.orderNoticeType
                // 入库通知
                this.$router.replace({
                    name: routerName == '自理销售' ? 'CreateStoreDetails' : 'CreateStoreDetails1',
                    params: {
                        id: this.$route.query.refId,
                        logId: this.$route.query.logId,
                        messageId: this.$route.query.messageId,
                    }
                })
            } else if (this.$route.query.messageType == '17') {
                // 收银通知
                this.$router.replace({
                    name: 'AdvancePayment',
                    params: {
                        id: this.$route.query.refId,
                        logId: this.$route.query.logId,
                    }
                })
            } else if (this.$route.query.messageType == '18') {
                // 供应商订单消息详情
                let routerName = this.$route.query.orderNoticeType
                let routerType = this.$route.query.supplierOrderType
                let productOrderType = this.$route.query.productOrderType
                if(productOrderType==='1'){
                    // 工程
                    this.$router.replace({
                        name: 'SupplierOrderDetail',
                        params: {
                            id: this.$route.query.refId,
                            logId: this.$route.query.logId,
                            messageId: this.$route.query.messageId,
                        }
                    })
                }else if(productOrderType==='2'){
                    // 自理销售
                    this.$router.replace({
                        name: 'SupplierOrderDetail1',
                        params: {
                            id: this.$route.query.refId,
                            logId: this.$route.query.logId,
                            messageId: this.$route.query.messageId,
                        }
                    })
                }else if (routerName == '自理销售') {
                    this.$router.replace({
                            name: 'ConfirmationSlip',
                            params: {
                                id: this.$route.query.refId,
                                logId: this.$route.query.logId,
                                messageId: this.$route.query.messageId,
                            }
                        })
                } else {
                    // 工程单
                    this.$router.replace({
                            name: 'ConfirmationSlip1',
                            params: {
                                id: this.$route.query.refId,
                                logId: this.$route.query.logId,
                                messageId: this.$route.query.messageId,
                            }
                        })
                }
            } else if (this.$route.query.messageType == '19') {
                // 分配产品专员通知
                this.$router.replace({
                    name: 'DistributionSpecial',
                    params: {
                        ... this.$route.query,
                        id: this.$route.query.refId,
                        logId: this.$route.query.logId,
                    }
                })
            } else if (this.$route.query.messageType == '20') {
                // 选材单审批通知
                this.$router.replace({
                    name: 'SelectionListDetails',
                    params: {
                        ... this.$route.query,
                        id: this.$route.query.refId,
                        logId: this.$route.query.logId,
                    }
                })
            } else if (this.$route.query.messageType == '21') {
                // 辅材申领查看 通知
                this.$router.replace({
                    name: 'engineeringMaterialsDetailsName',
                    params: {
                        id: this.$route.query.refId,
                        logId: this.$route.query.logId,
                        messageId: this.$route.query.messageId,
                        applyId: this.$route.query.applyId,
                    }
                })
            } else if (this.$route.query.messageType == '22' || this.$route.query.messageType == '23') {
                // 入库审核通知
                this.$router.replace({
                    name: 'SupplierOrderDetail2',
                    params: {
                        id: this.$route.query.refId,
                        logId: this.$route.query.logId,
                        messageId: this.$route.query.messageId,
                    }
                })
            } else if (this.$route.query.messageType == '25') {
                  // 预算单变更审核通过通知
                this.$router.replace({
                    name: 'ChangeBudget',
                    params: {
                        id: this.$route.query.refId,
                        logId: this.$route.query.logId,
                        messageId: this.$route.query.messageId,
                        orderInfoId: this.$route.query.orderInfoId,
                    }
                })
            } else {
                //手动消息
                this.$router.replace({
                    name: "messageInfoName",
                    query: {
                        messageId: this.$route.query.messageId,
                        logId: this.$route.query.logId
                    }
                })
            }
        }
      } else {
          this.isErro = true
      }
    }
  },
  created () {
    this.code = this.$route.query.code
    this.type = this.$route.query.state
    sessionStorage.setItem('APPID', this.type)
    this.getUserId()
  },
}
</script>

<style scoped lang="scss">
.homepage-style {
  height: 100vh;
  // background: #F5F6F8;
  display: flex;
  align-items: center;
  justify-content: center;

  .model-item-style {
    margin: 10px;
  }

  .clientsystem:nth-child(3) {
    margin-top: 10px;

  }

  .clientsystem:nth-child(2) {
    height: 157px;
  }

  .clientsystem {
    // width: 710px;
    // height: 140.5px;
    background: #FFFFFF;
    border-radius: 6px;

    .clientsystem-title {
      line-height: 12.5px;
      font-size: 13px;
      // font-family: Source Han Sans SC;
      font-weight: 400;
      color: #777777;
      text-align: left;
      padding: 13.5px 0 13px 10px;
      border-bottom: 1px solid #E6E6E6;
    }

    .clientsystem-box {
      display: flex;
      flex-wrap: wrap;

      // height: 101px;
      // align-items: center;
      .clientsystem-item {
        width: 117px;
        height: 101px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #E6E6E6;
        border-bottom: 1px solid #E6E6E6;

        .clientsystem-img {
          font-size: 30px;
        }

        .clientsystem-text {
          line-height: 13px;
          font-size: 14px;
          // font-family: Source Han Sans SC;
          font-weight: 400;
          color: #333333;
          margin-top: 10px;
        }
      }

      .clientsystem-item:nth-child(n+4) {
        border-bottom: none;
      }

      .clientsystem-item:nth-child(3n+0) {
        border-right: none;
      }
    }
  }

  .clientsystem2 {
    margin-top: 10px;

    .clientsystem-box {
      .clientsystem-item:nth-child(n+0) {
        border-bottom: none;
      }

      .clientsystem-item:nth-child(3n+0) {
        border-right: none;
      }
    }

  }
}
</style>
